import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"

const ContactPage = () => {
  return (
    <Layout title="Contact">
      <Head title="Contact" description="Contact the society."/>
      <div className="content">
        <p>For all correspondence with the society, please write to:</p>
        <p style={{paddingLeft:"2rem"}}><a href="mailto:contact@irishskeptics.org">contact@irishskeptics.org</a></p>
        <p>To receive notice of upcoming events please sign up to <a href="https://groups.google.com/forum/#!forum/irish-skeptics/join">our mailing list</a>.</p>
      </div>
    </Layout>
  )
}

export default ContactPage